.section {
	position: relative;
	padding: 100px 0;
	color: #fff;
	@include r(768) {
		padding: 50px 0; } }

.content {
	max-width: 1560px;
	padding: 0 50px;
	margin: 0 auto;
	@include r(768) {
		padding: 0 20px; } }

.flex {
	display: flex;
	flex-flow: row wrap; }

.justify-content-between {
	justify-content: space-between; }

.justify-content-end {
	justify-content: flex-end; }


// FLY

.fly {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	&-el {
		position: absolute; }
	img {
		display: block;
		max-width: 100%;
		user-select: none; } }

// HEADER

.header {
	min-height: 100vh;
	background: var(--sea) url('/assets/beauty/img/bg.jpg') no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	@include r(425) {
		background-image: url('/assets/beauty/img/bg-mob.jpg');
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between; }
	&-topline {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px 40px 0;
		@include r(768) {
			padding: 20px 20px 0; }
		&--logo {
			line-height: 1;
			.icon {
				font-size: 40px;
				margin-right: 15px; }
			span {
				display: block;
				text-align: right; } } }
	&-phone {
		color: #fff;
		display: block;
		padding: 5px 10px;

		border-radius: 3px;
		&:hover {
			color: #fff;
			text-decoration: none;
			background: $red; } }
	&-socials {
		display: flex;
		align-items: center;
		position: absolute;
		left: 100%;
		bottom: 0;
		padding-bottom: 40px;
		transform: rotate(-90deg);
		transform-origin: 0 100%;
		z-index: 15;
		@include r(768) {
			display: none; }
		&:before {
			content: '';
			display: block;
			height: 2px;
			width: 100px;
			background: #fff;
			margin-right: 10px; } }

	&-content {
		padding: 100px;
		max-width: 1600px;
		margin: 0 auto;
		position: relative;
		z-index: 0;
		@include r(768) {
			padding: 100px 20px 20px; }
		h1 {
			max-width: 50%; }
		@include r(425) {
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(to top, #222f3f, rgba(#222f3f,.75), rgba(#222f3f, 0));
				z-index: -1; }
			h1 {
				max-width: 100%;
				font-size: 14px; }
			.button {
				width: 100%;
				margin: 0 !important; } } }

	&-title {
		margin-bottom: 20px;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 600;
		white-space: nowrap;
		@include css-lock(48, 90, 600, 1600);
		@include r(425) {
			margin-bottom: 0;
			font-size: 40px; } } }

.arrow {
	display: inline-block;
	padding: 20px;
	animation: 2s arrow infinite ease-out;
	@include r(600) {
		display: none; }

	span, span:before, span:after {
		display: block;
		background: #fff; }
	span {
		position: relative;
		width: 4px;
		height: 80px;

		&:before, &:after {
			position: absolute;
			content: '';
			width: 4px;
			height: 20px;
			bottom: 0;
			left: 0;
			transform: rotate(-45deg);
			transform-origin: 150% 100%; }
		&:after {
			transform: rotate(45deg);
			transform-origin: -50% 100%; } } }

@keyframes arrow {
	0%, 10% {
		transform: translateY(-10px);
		opacity: 0; }
	95% {
		opacity: 1; }
	100% {
		transform: translateY(10px);
		opacity: 0; } }

// MINUSES

.s-minus {
	background: #222f3f url('/img/bg-minus.jpg') 50% 50% no-repeat;
	background-size: cover;
	position: relative;
	z-index: 0;
	@include r(425) {
		background: #222f3f; }
	.flex {
		justify-content: space-between; }

	.fly-el {
		&:nth-child(1) {
			bottom: -580px;
			right: -275px;
			width: 500px;
			z-index: 1;
			@include r(768) {
				width: 300px;
				right: -200px;
				bottom: -400px; } }
		&:nth-child(2) {
			width: 550px;
			bottom: -560px;
			right: -275px;
			z-index: 2;
			@include r(768) {
				width: 300px;
				right: -200px;
				bottom: -400px; } } } }

.minus--list {
	flex: 1 1 320px;
	padding-right: 20px;
	li {
		margin-bottom: 18px; }
	.fa {
		color: var(--sea); } }

.minus--stat {
	flex: 1 1 320px;
	p {
		padding-top: 40px;
		padding-left: 40px;
		flex: 1 1 320px;
		@include r(768) {
			padding-top: 0;
			padding-left: 0;
			font-size: 14px; } }
	&-percent {
		position: relative;
		font-size: 110px;
		font-weight: 600;
		line-height: 1;
		padding-top: 30px;
		color: var(--sea);
		span {
			top: 0;
			left: 0;
			position: absolute;
			font-size: 40px;
			color: #fff; } } }

.section-border {
	border-bottom: 1px solid rgba(#fff,.25); }

///// BENEFITS

.s-benefits {

	.fly-el {
		&:nth-child(1) {
			width: 1000px;
			height: 1000px;
			border-radius: 100%;
			border: 170px solid #75CCDA;
			left: -250px;
			bottom: 0;
			z-index: -1;
			img {
				transform: rotate(180deg); } }
		&:nth-child(2) {
			width: 250px;
			bottom: -25px;
			left: -50px;
			z-index: -1; }
		&:nth-child(3) {
			width: 320px;
			bottom: -150px;
			left: -120px;
			z-index: -1; }
		&:nth-child(4) {
			width: 330px;
			bottom: -500px;
			right: -120px;
			z-index: -1;
			img {
				transform: rotate(35deg); }
			@include r(768) {
				right: -200px;
				bottom: -1100px; } } }

	&--title {
		margin-bottom: 20px;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 600;
		@include css-lock(48, 90, 600, 1600);
		@include r(425) {
			font-size: 32px; } }
	&--subtitle {
		font-size: 24px;
		line-height: 1.2; } }

.benefits--row {
	padding-top: 30px; }

.benefits--item {
	flex: 1 1 280px;
	margin-right: 20px;
	margin-bottom: 20px;
	@include r(425) {
		flex-basis: 100%;
		margin-bottom: 10px; }
	.benefits--line {
		background: rgba(#fff, .25);
		width: 60px;
		height: 4px;
		margin-bottom: 10px; }
	@include r(1299) {
		flex-basis: calc(50% - 20px); } }

.benefits--item-white {
	flex: 1 1 320px;
	background: #fff;
	color: var(--dark);
	margin-left: 20px;
	margin-bottom: 20px;
	@include r(425) {
		margin-left: 0; }
	.benefits--percent {
		position: relative;
		padding: 20px 30px;
		font-size: 140px;
		font-weight: 900;
		line-height: 1;
		white-space: nowrap;
		letter-spacing: 0;
		@include r(425) {
			font-size: 90px; }
		sub {
			position: static;
			font-size: 30px; }
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 70px;
			height: 10px;
			bottom: 0;
			left: 0;
			background: var(--sea); } }
	p {
		padding: 20px 30px 0; }

	@include r(1460) {
		flex-basis: 280px;
		p {
			font-size: 16px;
			padding: 20px 20px 0; } }
	@include r(1299) {
		flex-basis: calc(50% - 20px); } }



.s-conclusion {
	&--title {
		margin-bottom: 20px;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 600;
		@include css-lock(48, 90, 600, 1600);
		@include r(425) {
			font-size: 32px; } } }

.conclusion--title {
	flex: 1 1 320px;
	margin-right: 80px;
	@include r(425) {
		margin-right: 0; } }

.conclusion--text {
	font-size: 16px;
	flex: 1 1 320px;
	opacity: .8;
	@include r(425) {
		font-size: 14px;
		line-height: 1.4;
		opacity: 1; } }

.s-faq {
	.fly-el {
		&:nth-child(1) {
			width: 100px;
			top: 1100px;
			left: -50px;
			z-index: -1;
			@include r(425) {
				width: 50px;
				left: -35px; } }
		&:nth-child(2) {
			width: 500px;
			top: 700px;
			right: -200px;
			z-index: -1;
			@include r(768) {
				top: 1200px; } }
		&:nth-child(3) {
			width: 300px;
			top: 630px;
			left: 10%;
			z-index: -1;
			@include r(768) {
				left: -200px;
				bottom: -800px; } } }

	.content {
		padding: 0 60px;
		@include r(768) {
			padding-left: 20px;
			padding-right: 0; } }
	&--title {
		font-size: 36px;
		text-transform: uppercase;
		font-weight: 600;
		display: inline-block;
		transform: translateX(-100%) rotate(-90deg);
		transform-origin: 100% 0;
		white-space: nowrap;
		position: relative;
		padding-left: 10px;
		&:before {
			content: '';
			display: block;
			height: 3px;
			width: 100px;
			background: #fff;
			margin-right: 10px;
			position: absolute;
			top: calc(50% - 2px);
			left: 0;
			transform: translateX(-100%); }
		@include r(425) {
			margin-left: -15px;
			font-size: 18px; } }
	.faq {
		position: relative;
		padding-left: 80px;
		height: 700px;
		overflow: hidden;
		transition: all .25s;
		@include r(768) {
			padding-left: 60px; }
		@include r(425) {
			padding-left: 0px; }
		&.shown {
			height: auto; }
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 200px;
			background: linear-gradient(to top, #62c8d8, rgba(#62c8d8,0));
			transition: all .25s;
			opacity: 1;
			z-index: 1; }
		&.shown:before {
			opacity: 0;
			z-index: -15; }
		&-column {
			flex: 1 1 320px;
			padding: 0 20px; }
		&-item {
			margin-bottom: 50px;
			@include r(768) {
				margin-bottom: 30px; }
			&-title {
				font-weight: 600;
				margin-bottom: 30px;
				@include r(768) {
					margin-bottom: 15px; } }
			&-text {
				font-size: 16px;
				opacity: .8;
				line-height: 1.4; } } }
	.show-faq {
		position: relative;
		text-align: center;
		z-index: 15;
		a {
			padding: 5px 20px;
			display: block;
			z-index: 15; }
		i {
			display: block;
			animation: 1.5s down infinite ease-out; } } }

@keyframes down {
	0%,10% {
		transform: translateY(0);
		opacity: 0; }
	90% {
		opacity: 1; }
	100% {
		transform: translateY(5px);
		opacity: 0; } }


.s-about {
	.fly-el {
		@include r(425) {
			display: none; }
		&:nth-child(1) {
			width: 240px;
			bottom: -600px;
			left: -75px;
			z-index: -1; }
		&:nth-child(2) {
			width: 240px;
			bottom: -300px;
			right: -75px;
			z-index: -1;
			img {
				transform: rotate(180deg); } }
		&:nth-child(3) {
			width: 300px;
			bottom: -500px;
			right: -75px;
			z-index: -1;
			img {
				transform: rotate(100deg); } }
		&:nth-child(4) {
			bottom: -500px;
			right: -75px;
			z-index: -1; } }

	.content {
		max-width: 1360px;
		@include r(425) {
			padding: 0 20px; } }
	&--title {
		font-size: 36px;
		text-transform: uppercase;
		font-weight: 600;
		display: inline-block;
		transform: translateX(-100%) rotate(-90deg);
		transform-origin: 100% 0;
		position: relative;
		padding-left: 10px;
		&:before {
			content: '';
			display: block;
			height: 3px;
			width: 100px;
			background: #fff;
			margin-right: 10px;
			position: absolute;
			top: calc(50% - 2px);
			left: 0;
			transform: translateX(-100%); }
		@include r(425) {
			margin-left: -15px;
			font-size: 18px; } }
	.about {
		padding-left: 80px;
		@include r(425) {
			padding-left: 20px; }
		&--price {
			position: absolute;
			left: 340px;
			top: 4px;
			line-height: 1;
			b {
				display: block;
				font-size: 48px; }
			@include r(700) {
				position: relative;
				left: 0;
				margin-top: 20px; } }
		&--plus {
			position: relative;
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 40px;
			white-space: nowrap;
			@include r(375) {
				font-size: 16px; } }
		&--list {
			margin-bottom: 40px;
			@include r(425) {
				margin-bottom: 0; }
			ul {
				margin-bottom: 0;
				flex: 1 1 320px;
				&:first-child {
					margin-right: 60px; }
				@include r(425) {
					font-size: 14px;
					margin-right: 0; } }

			li {
				margin-bottom: 10px;
				font-weight: 500; }
			.fa {
				margin-right: 10px;
				opacity: .5; }

			&-exp {
				line-height: 1.2;
				font-size: .8em;
				opacity: .7;
				font-weight: 300; } } } }
.s-video {
	padding-bottom: 0;
	&-title {
		text-align: center;
		text-transform: uppercase;
		font-weight: 600; } }

.video {
	position: relative;
	display: block;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	box-shadow: 0 10px 30px -15px rgba(#000, .5);
	overflow: hidden;
	border-radius: 5px;

	.fa {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 40px;
		color: #fff;
		transform: translate(-50%, -50%);
		transition: all .25s ease-out; }

	&:hover .fa {
		transform: translate(-50%, -50%) scale(1.2); } }


.footer {
	background: var(--dark);
	padding: 50px 0;
	.content {}
	.flex {
		justify-content: center;
		align-items: center; }
	.logo {
		padding: 5px;
		img {
			display: inline-block;
			max-width: 100%;
			@include r(375) {
				max-height: 40px; } } } }

// START
.start {
	display: flex;
	// display: none
	justify-content: center;
	align-items: center;
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--sea);
	z-index: 9999;
	transform: translateX(-100%);
	transition: all .1s ease-out;
	.stagger {
		transition: all .5s cubic-bezier(.5,-0.5,.5,1.5);
		transform: translateX(-100px);
		opacity: 0; }
	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		&_btn {
			display: block;
			position: relative;
			width: 50px;
			height: 50px;
			span {
				position: absolute;
				width: 30px;
				height: 30px;
				top: calc(50% - 15px);
				left: calc(50% - 15px);

				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: calc(50% - 1px);
					background: #fff;
					width: 30px;
					height: 2px;
					transform: rotate(45deg); }
				&:after {
					transform: rotate(-45deg); } } } }
	&__content {
		max-width: 500px; }
	&__title {
		font-size: 36px;
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 20px; }
	&__text {
		margin-bottom: 40px; }
	&__buttons {
		.button {
			margin-bottom: 10px; }
		span {
			display: inline-block;
			font-size: 14px; } }


	&__shown {
		transform: translateX(0);
		.stagger {
			transform: translateX(0);
			opacity: 1;
			&:nth-child(1) {
				transition-delay: .1s; }
			&:nth-child(2) {
				transition-delay: .2s; }
			&:nth-child(3) {
				transition-delay: .3s; }
			&:nth-child(4) {
				transition-delay: .4s; }
			&:nth-child(5) {
				transition-delay: .5s; }
			&:nth-child(6) {
				transition-delay: .6s; } } } }


.pay {
	&_header {
		padding: 30px 40px 0;
		@include r(768) {
			padding: 20px 20px 0; }

		&_logo {
			line-height: 1;
			.icon {
				font-size: 40px;
				margin-right: 15px; }
			span {
				display: block;
				text-align: right; } } }
	&_content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 480px; }
	&_title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 20px; }
	&_options {
		.button {
			@include r(600) {
				width: 100%; } } }
	&_sum {
		margin-bottom: 1em;
		span {
			font-weight: 600;
			font-size: 2em; } } }

.modal {
	display: none;
	max-width: 420px;
	color: #666;
	border-radius: 15px;
	.card {
		text-align: center;
		margin-bottom: 20px; }
	&-body {
		button {
			width: 100%; } }
	&-footer {
		p:last-child {
			margin-bottom: 0; } } }

.fancybox-close-small:hover {
	color: var(--sea); }

.fancybox-slide {
	@include r(600) {
		padding: 10px; } }
.fancybox-slide--video .fancybox-content {
	border-radius: 5px;
	overflow: hidden; }
