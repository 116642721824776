.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: none;
}
.icon-logo {
  width: 1.25em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-viber {
  width: 1em;
  height: 1em;
  fill: initial;
}
