// Подлключаем шрифты
@include font-face(MuseoSans, light, /fonts/MuseoSansLight, 300, normal, false );
@include font-face(MuseoSans, regular, /fonts/MuseoSansRegular, 400, normal, false );
@include font-face(MuseoSans, medium, /fonts/MuseoSansMedium, 500, normal, false );
@include font-face(MuseoSans, bold, /fonts/MuseoSansBold, 600, normal, false );
@include font-face(MuseoSans, black, /fonts/MuseoSansBlack, 900, normal, false );

 :root {
	--white: #fff;
	--dark: #222F3F;
	--sea: #62c8d8;
	--red: #FE1C37;
	--darkgradient: #265361 #222F3F #222F3F #265361; }

$red: #FE1C37;

$default-font: "MuseoSans", sans-serif;

::-webkit-scrollbar {
	width: 10px; }

::-webkit-scrollbar-thumb {
	background-color: #222f3f;
	cursor: pointer;
	border-radius: 2px; }

::-webkit-scrollbar-track {
	background: #f6f6f6;
	border-radius: 2px; }

::selection {
	background: #222f3f;
	color: #fff;
	text-shadow: none; }

body {
	font-size: 18px;
	line-height: 1.6;
	font-family: $default-font;
	// +css-lock(16, 50, 600, 1000)
	min-height: 100vh;
	background: var(--sea);
	color: #fff;
	@include r(425) {
		font-size: 16px; }
	&.start__is_shown {
		overflow: hidden; } }

.out {
	overflow: hidden; }


strong {
	font-weight: normal; }

h1, h2, h3, h4, h5, h6 {
	font-size: 1rem;
	font-weight: normal; }

.h1, .h2, .h3, .h4, .h5, .h6 {}

.h2 {
	font-size: 24px; }

.icon {
	fill: currentColor; }

.link, .button {
	display: inline-block;
	&:hover {
		text-decoration: none; } }

.img-responsive {
	display: block;
	max-width: 100%; }

.text-center {
	text-align: center; }

.link {
	&-white {
		color: #fff;
		&:hover {
			color: #fff; } } }

.button-block {
	max-width: 400px;
	width: 100%;
	margin-bottom: 10px;
	.button {
		width: 100%; } }

.button {
	padding: 20px 30px;
	background: var(--red);
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
	border-radius: 3px;
	text-align: center;
	transition: all .25s ease-out;
	@include r(425) {
		font-size: 14px;
		padding: 20px 5px; }
	&:hover {
		color: #fff;
		background: darken($red, 5%);
		transform: translateY(-5px);
		box-shadow:  0 5px 20px -10px rgba(#000, .25); }
	span {
		display: inline-block;
		margin: 0 5px; }
	.fa-chevron-right,.fa-chevron-left {
		letter-spacing: -3px;
		animation: 2s blink ease-out infinite;
		&:nth-child(1),
		&:nth-child(6) {
			opacity: .25; }
		&:nth-child(2),
		&:nth-child(5) {
			opacity: .5;
			animation-delay: .25s; }
		&:nth-child(3),
		&:nth-child(4) {
			opacity: .75;
			animation-delay: .5s; } }

	&-big {
		display: block; }

	&-circle {
		padding: 0;
		height: 85px;
		line-height: 80px;
		width: 85px;
		font-size: 40px;
		border-radius: 100%;
		text-align: center;
		margin: 0 10px;
		@include r(425) {
			padding: 0;
			font-size: 40px; } }

	&.disabled {
		background: #999; } }


@keyframes blink {
	0%, 100% {
		opacity: 0; }
	40% {
		opacity: 1; } }


input[type=range] {
	width: 100%;
	outline: 0;
	-webkit-appearance: none;
	cursor: pointer;
	vertical-align: middle;
	background-color: #ddd;
	border-radius: 3px;
	height: 3px;
	margin: 20px 0 30px; }

label[range] {
	display: block;
	position: relative;
	.range-text {
		padding-right: 20px; }
	.range-value {
		position: absolute;
		top: 0;
		right: 0;
		background: var(--sea);
		color: #fff;
		min-width: 1.8em;
		height: 1.8em;
		text-align: center;
		line-height: 1.8em;
		border-radius: 2em; } }

input[type=range] {
	width: 100%;
	outline: 0;
	-webkit-appearance: none;
	cursor: pointer;
	vertical-align: middle;
	background-color: #ddd;
	border-radius: 3px;
	height: 3px;
	margin: 20px 0 30px;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		background: #fff;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		border: 1px solid #ddd;
		box-shadow: 0 4px 4px rgba(0,0,0,.1);
		transition: all .25s;
		&::-webkit-slider-thumb:active {
			width: 32px;
			height: 32px; } } }

.small {
	font-size: .8em;
	line-height: 1.4; }



// FORM


.field {
	.field-wrap {
		position: relative;
		display: block;
		margin-bottom: 30px;
		z-index: 1;
		.button {
			display: block;
			width: 100%; } }

	.field-black {
		background: var(--black);
		color: var(--white); }

	.field-label, .field-input {
		background: none;
		text-align: center;
		line-height: 1;
		letter-spacing: .03em;
		font-weight: 600;
		text-transform: uppercase; }

	.field-label {
		position: absolute;
		top: calc(50% - .5em);
		left: 0;
		right: 0;
		@include css-lock(12, 14, 600, 1200);
		transition: all .25s;
		user-select: none;
		pointer-events: none;
		transform: translateY(-1.4em); }

	.field-input {
		width: 100%;
		max-width: 100%;
		padding: 12/18*1em;
		color: var(--black);
		border-radius: 0;
		border-bottom: 1px solid #eee;
		@include css-lock(18, 24, 600, 1200);
		&::-webkit-input-placeholder {
			color: transparent;
			transition: color .5s; }
		&:focus {
			border-color: var(--black);
			&::-webkit-input-placeholder {
				color: var(--gray); } }
		&.empty-field {
			&:focus + .field-label {
				@include css-lock(12, 14, 600, 1200);
				transform: translateY(-1.4em); }

			+ .field-label {
				@include css-lock(18, 24, 600, 1200);
				transform: translateY(0); } } }

	.field-danger {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		font-size: 12/18*1em; } }



.modal {
	width: 100%;
	max-width: 400px; }
